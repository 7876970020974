<template>
  <v-dialog v-model="show" width="500" persistent scrollable>
    <v-card>
      <v-card-title class="primary justify-center white--text align-content-center" style="height: 12%">
        <v-spacer/>
        <span class="ml-9">{{ titulo }}</span>
        <v-spacer/>
        <v-btn :disabled="loading" dark icon @click="resetar(true)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="text-center mt-2"
      >{{ subTitulo }}
      </v-card-subtitle>
      <v-card-text>
        <keep-alive>
          <v-window v-model="step" touchless>
            <v-window-item :value="1" eager>
              <v-form ref="loginForm" @submit.prevent="entrar" class="mt-2">
                <v-text-field
                    v-model="loginFormInputs.cpf"
                    v-mask="'###########'"
                    :counter="11"
                    :rules="[campoObrigatorioRule, tamanhoCPFRule]"
                    dense
                    label="CPF"
                    maxlength="11"
                    name="cpfLogin"
                    outlined
                    prepend-icon="mdi-account"
                    validate-on-blur
                ></v-text-field>
                <v-text-field
                    v-model="loginFormInputs.senha"
                    :rules="[campoObrigatorioRule, tamanhoSenhaRule]"
                    dense
                    label="Senha"
                    name="senhaLogin"
                    outlined
                    prepend-icon="mdi-form-textbox-password"
                    type="password"
                    validate-on-blur
                ></v-text-field>
                <v-card-actions>
                  <v-btn
                      :loading="loading"
                      block
                      class="mb-2"
                      color="primary"
                      type="submit"
                  >Entrar
                  </v-btn>
                </v-card-actions>
                <v-divider/>
                <v-container>
                  <v-row>
                    <v-col>
                      <span>Esqueceu a senha?</span>
                      <a
                          class="ml-2"
                          color="anchor"
                          @click="stepToRecuperarSenha"
                      >Recuperar senha</a
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span>Primeiro acesso?</span>
                      <a class="ml-2" color="anchor" @click="stepToCadastrar"
                      >Criar conta</a
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-window-item>
            <v-window-item :value="2" eager>
              <v-form
                  ref="recuperarSenhaForm"
                  @submit.prevent="enviarEmailRecuperarSenha"
                  class="mt-2"
              >
                <v-text-field
                    v-model="recuperarSenhaFormInputs.cpf"
                    v-mask="'###########'"
                    :counter="11"
                    :rules="[campoObrigatorioRule, tamanhoCPFRule]"
                    dense
                    label="CPF *"
                    maxlength="11"
                    name="cpfLogin"
                    outlined
                    validate-on-blur
                ></v-text-field>
                <v-card-actions>
                  <v-btn
                      :loading="loading"
                      block
                      class="mb-2"
                      color="primary"
                      type="submit"
                  >Enviar Email
                  </v-btn>
                </v-card-actions>
                <v-divider/>
                <v-container>
                  <v-row>
                    <v-col>
                      <span>Já possui cadastro?</span>
                      <a class="ml-2" color="anchor" @click="resetar(false)"
                      >Entrar</a
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span>Primeiro acesso?</span>
                      <a class="ml-2" color="anchor" @click="stepToCadastrar"
                      >Criar conta</a
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-window-item>
            <v-window-item :value="3" eager>
              <v-form
                  ref="cadastroForm"
                  @submit.prevent="cadastrar"
                  class="mt-2"
              >
                <v-text-field
                    v-model="cadastroFormInputs.cpf"
                    v-mask="'###########'"
                    :counter="11"
                    :rules="[campoObrigatorioRule, tamanhoCPFRule]"
                    dense
                    label="CPF *"
                    maxlength="11"
                    name="cpfCadastro"
                    outlined
                    validate-on-blur
                ></v-text-field>
                <v-text-field
                    v-model="cadastroFormInputs.nome"
                    :rules="[campoObrigatorioRule, formatoNomeRule]"
                    dense
                    label="Nome *"
                    name="nomeCadastro"
                    outlined
                    validate-on-blur
                ></v-text-field>
                <v-text-field
                    v-model="cadastroFormInputs.dataNascimento"
                    v-mask="'##/##/####'"
                    :rules="[campoObrigatorioRule, formatoDataRule]"
                    dense
                    label="Data de Nascimento *"
                    name="dataNascimentoCadastro"
                    outlined
                    validate-on-blur
                    hint="Formato: DD/MM/AAAA"
                    persistent-hint
                ></v-text-field>
                <v-text-field
                    v-model="cadastroFormInputs.mae"
                    :rules="[campoObrigatorioRule, formatoNomeRule]"
                    dense
                    label="Mãe *"
                    name="maeCadastro"
                    outlined
                    validate-on-blur
                ></v-text-field>
                <v-text-field
                    v-model="cadastroFormInputs.email"
                    :rules="[campoObrigatorioRule, formatoEmailRule, formatoEmail]"
                    dense
                    label="Email *"
                    name="emailCadastro"
                    outlined
                    validate-on-blur
                ></v-text-field>
                <v-text-field
                    v-model="cadastroFormInputs.confirmacaoEmail"
                    :rules="[
                    campoObrigatorioRule,
                    camposIguaisRule(
                      cadastroFormInputs.confirmacaoEmail,
                      cadastroFormInputs.email,
                      'Email diferente do informado!'
                    ),
                    formatoEmailRule, formatoEmail
                  ]"
                    dense
                    label="Confirmação de Email *"
                    outlined
                    validate-on-blur
                ></v-text-field>
                <v-text-field
                    v-model="cadastroFormInputs.senha"
                    :rules="[campoObrigatorioRule, tamanhoSenhaRule]"
                    dense
                    hint="Mínimo de 8 caracteres"
                    label="Senha *"
                    minlength="8"
                    outlined
                    type="password"
                    validate-on-blur
                ></v-text-field>
                <v-text-field
                    v-model="cadastroFormInputs.confirmacaoSenha"
                    :rules="[
                    campoObrigatorioRule,
                    camposIguaisRule(
                      cadastroFormInputs.confirmacaoSenha,
                      cadastroFormInputs.senha,
                      'Senha diferente da informada!'
                    )
                  ]"
                    dense
                    hint="Mínimo de 8 caracteres"
                    label="Confirmação de Senha *"
                    minlength="8"
                    outlined
                    type="password"
                    validate-on-blur
                ></v-text-field>
                <v-card-actions>
                  <v-btn
                      :loading="loading"
                      block
                      class="mb-2"
                      color="primary"
                      type="submit"
                  >Cadastrar
                  </v-btn>
                </v-card-actions>
                <v-divider/>
                <v-container>
                  <v-row>
                    <v-col>
                      <span>Já possui cadastro?</span>
                      <a class="ml-2" color="anchor" @click="resetar(false)"
                      >Entrar</a
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-window-item>
          </v-window>
        </keep-alive>
      </v-card-text>
      <v-divider/>
      <v-card-subtitle class="text-center" style="height: 12%"
        ><small>
          Este site é protegido pelo reCAPTCHA e pelo Google<br/>
          <a href="https://policies.google.com/privacy">Privacidade</a>
          -
          <a href="https://policies.google.com/terms">Termos</a>
        </small>
      </v-card-subtitle>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/commons/rules";

import {mapActions, mapGetters, mapState} from "vuex";
import recaptchaService from "@/services/recaptcha.service";
import recuperarSenhaService from "@/services/recuperarSenha.service";
import usuarioService from "@/services/usuario.service";

export default {
  name: "LoginForm",

  data() {
    return {
      titulo: "Bem-vindo!",
      subTitulo: "Insira o seu CPF e senha",
      step: 1,
      loading: false,
      loginFormInputs: {
        cpf: "",
        senha: ""
      },
      recuperarSenhaFormInputs: {
        cpf: ""
      },
      cadastroFormInputs: {
        cpf: "",
        nome: "",
        dataNascimento: "",
        mae: "",
        email: "",
        confirmacaoEmail: "",
        senha: "",
        confirmacaoSenha: ""
      }
    };
  },

  computed: {
    ...mapState("autenticacao", {
      irPara: state => state.loginDialogIrPara,
      show: state => state.loginDialogAberto
    })
  },

  methods: {
    ...rules,
    ...mapActions("autenticacao", ["exibirLoginEIrPara", "login"]),
    ...mapGetters("autenticacao", ["isAdministrador", "isAvaliador"]),
    ...mapActions("avisos", ["exibirAviso"]),

    resetar(fecharDialog) {
      this.exibirLoginEIrPara({exibir: !fecharDialog, irPara: undefined});
      setTimeout(
          () => {
            this.$refs.loginForm.reset();
            this.$refs.recuperarSenhaForm.reset();
            this.$refs.cadastroForm.reset();
            this.step = 1;
            this.titulo = "Bem-Vindo!";
            this.subTitulo = "Insira o seu CPF e senha";
            this.loading = false;
          },
          fecharDialog ? 500 : 0
      );
    },

    stepToRecuperarSenha() {
      this.step = 2;
      this.titulo = "Recuperar Senha";
      this.subTitulo = "Um email será enviado para confirmar a sua nova senha";
    },

    stepToCadastrar() {
      this.step = 3;
      this.titulo = "Cadastrar";
      this.subTitulo = "Um email será enviado para confirmar o seu cadastro";
    },

    async entrar() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        if (await recaptchaService.verificarRecaptcha("login")) {
          this.login({
            usuario: this.loginFormInputs.cpf,
            senha: this.loginFormInputs.senha
          })
              .then(() => {
                if (this.irPara) {
                  this.$router.push(this.irPara).catch(() => {
                  });
                } else if (this.isAdministrador() || this.isAvaliador()) {
                  this.$router.push("/dashboard").catch(() => {
                  });
                }
                this.resetar(true);
              })
              .catch(error => {
                if(error.response.data.codigoErro === 'US04'){
                  this.exibirAviso({
                    mensagem: "Usuário não habilitado para Login.",
                    tipo: "error"
                  });
                }
                else{
                  this.exibirAviso({
                    mensagem: "Usuário e/ou senha inválidos. Tente novamente! Caso seja o seu Primeiro Acesso ao SGA, utilize a opção Criar Conta",
                    tipo: "error"
                  });
                }
                this.resetar(false);
              });
        }
      }
    },

    async enviarEmailRecuperarSenha() {
      if (this.$refs.recuperarSenhaForm.validate()) {
        this.loading = true;
        if (await recaptchaService.verificarRecaptcha("recuperarSenha")) {
          recuperarSenhaService
              .recuperarSenha({
                cpf: this.recuperarSenhaFormInputs.cpf
              })
              .then(response => {
                this.resetar(true);
                this.exibirAviso({
                  mensagem: "Um email com o link para recuperar sua senha foi enviado para: " + response.data,
                  tipo: "info"
                });
              })
              .catch((error) => {
                this.loading = false;
                let mensagem = error.response.data.msg;
                if(error.response.data.codigoErro === 'CP02')
                {
                  mensagem = "CPF não cadastrado! Caso seja o seu Primeiro Acesso ao SGA, utilize a opção Criar Conta"
                }
                this.exibirAviso({
                  mensagem: mensagem,
                  tipo: "error"
                });
              });
        }
      }
    },

    async cadastrar() {
      if (this.$refs.cadastroForm.validate()) {
        this.loading = true;
        if (await recaptchaService.verificarRecaptcha("primeiroAcesso")) {
          usuarioService
              .enviarEmailPrimeiroAcesso({
                cpf: this.cadastroFormInputs.cpf,
                nome: this.cadastroFormInputs.nome.replace(/\s+$/, ''),
                dataNascimento: this.cadastroFormInputs.dataNascimento,
                mae: this.cadastroFormInputs.mae.replace(/\s+$/, ''),
                email: this.cadastroFormInputs.email.replace(" ", ""),
                senha: this.cadastroFormInputs.senha
              })
              .then(() => {
                this.resetar(true);
                this.exibirAviso({
                  mensagem: "Um email foi enviado com um link para confirmar o seu cadastro",
                  tipo: "info"
                });
              })
              .catch(error => {
                let mensagem = "Não foi possível realizar o cadastro";
                if(error.response.status === 409){
                  mensagem += ": " + error.response.data.msg;
                }
                this.exibirAviso({
                  mensagem,
                  tipo: "error"
                });
                this.resetar(true);
              });
        }
      }
    }
  }
};
</script>
