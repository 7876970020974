<template>
  <v-menu v-if="usuarioLogado" offset-y transition="slide-x-transition">
    <template v-slot:activator="{ on, attrs }">
      <transition
          appear
          enter-active-class="animate__animated animate__bounceIn"
      >
        <div class="d-flex" v-bind="attrs" v-on="on">
          <div  class="d-none d-md-block large-width-only text-right align-center">
            <p class="mb-n2" id="boas-vindas">Olá,</p>
            <h4 id="nome-usuario">{{ primeiroNome }}</h4>
          </div>
            <v-btn key="menu" class="ml-1 align-center" text>
              <v-icon>mdi-menu</v-icon>
            </v-btn>
        </div>
      </transition>
    </template>

    <v-list class="overflow-y-auto" max-height="90vh">
      <div class="d-block d-md-none">
        <v-list-item>
          <v-list-item-content>
            <v-row no-gutters>
              <span>Olá,</span>
              <h4 class="ml-1" id="nome-usuario">{{ primeiroNome }}!</h4>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
      </div>

      <v-list-item color="info" to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            Página Inicial
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div v-if="isAdministrador() || isAvaliador()" >
        <v-list-item color="info" to="/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Dashboard
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div v-if="isAdministrador()">
        <v-divider/>

        <v-subheader @click.stop>Administração</v-subheader>

        <v-list-item color="info" to="/admin/estatisticas">
          <v-list-item-icon>
            <v-icon>mdi-chart-line</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Estatísticas
          </v-list-item-title>
        </v-list-item>

        <v-list-item color="info" to="/admin/relatorios">
          <v-list-item-icon>
            <v-icon>mdi-file-chart</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Relatórios
          </v-list-item-title>
        </v-list-item>

        <v-list-item color="info" to="/admin/materialAplicacao">
          <v-list-item-icon>
            <v-icon>mdi-file-document-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Material de Aplicação
          </v-list-item-title>
        </v-list-item>

        <v-list-item color="info" to="/admin/envioDocumentos">
          <v-list-item-icon>
            <v-icon>mdi-file-document-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Envio de Documentos
          </v-list-item-title>
        </v-list-item>


        <v-list-item color="info" to="/admin/processos">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Processos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="info" to="/admin/divulgacao">
          <v-list-item-icon>
            <v-icon>mdi-bullhorn-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Divulgação
          </v-list-item-title>
        </v-list-item>

        <v-list-group
            color="grey darken-2"
            no-action
            @click.stop
            prepend-icon="mdi-magnify"
        >
          <template v-slot:activator>
            <v-list-item-title>Consulta</v-list-item-title>
          </template>

          <v-list-item color="info" to="/admin/consulta/usuarios">
            <v-list-item-content>
              <v-list-item-title>
                Usuários
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item color="info" to="/admin/consulta/dadosPessoais">
            <v-list-item-content>
              <v-list-item-title>
                Dados Pessoais
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item color="info" to="/admin/consulta/inscricao">
            <v-list-item-content>
              <v-list-item-title>
                Inscrições
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item color="info" to="/admin/consulta/classificacao">
            <v-list-item-content>
              <v-list-item-title>
                Classificação
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item color="info" to="/admin/consulta/notaEnem">
            <v-list-item-content>
              <v-list-item-title>
                Notas Enem
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item color="info" to="/admin/consulta/cep">
            <v-list-item-content>
              <v-list-item-title>
                CEP
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item color="info" to="/admin/consulta/instituicaoEnsino">
            <v-list-item-content>
              <v-list-item-title class="text-pre-line">
                Instituição de Ensino Superior
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item color="info" to="/admin/consulta/HistoricoInscricao">
            <v-list-item-content>
              <v-list-item-title class="text-pre-line">
                Histórico de Inscrição
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>
      </div>
      <div v-if="mostrarMenu()">
        <v-divider/>

        <v-subheader @click.stop>Avaliação</v-subheader>

        <v-list-item v-if="isAdministrador()" color="info" to="/avaliadores">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Avaliadores
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdministrador() || isAvaliador()" color="info" to="/lancamento">
          <v-list-item-icon>
            <v-icon>mdi-check-all</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Lançar Resultados
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdministrador()" color="info" to="/lancamentoAdministrativo">
          <v-list-item-icon>
            <v-icon>mdi-account-check-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Lançamento Administrativo
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item v-if="isAdministrador() || isAvaliador()" color="info" to="/documentacao">
          <v-list-item-icon>
            <v-icon>mdi-file-eye</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Visualizar Documentos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div>
        <v-divider/>
        <v-subheader @click.stop>Conta</v-subheader>

        <v-list-item color="info" to="/trocarSenha">
          <v-list-item-icon>
            <v-icon>mdi-form-textbox-password</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Alterar Senha
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.prevent="sair()">
          <v-list-item-icon>
            <v-icon color="error">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Sair
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "Menu",

  data() {
    return {
      showMenu: true
    };
  },

  methods: {
    ...mapGetters("autenticacao", ["isAdministrador", "isAvaliador"]),

    ...mapActions("autenticacao", ["exibirLogin", "logout"]),

    sair() {
      this.logout();
      window.localStorage.setItem("logout-event", "logout" + Math.random());
    },

    mostrarMenu() {
      return this.isAdministrador() || this.isAvaliador();
    }
  },

  computed: {
    ...mapState("autenticacao", ["usuarioLogado"]),

    primeiroNome() {
      return this.usuarioLogado.nome.split(" ")[0]
    }
  }
};
</script>

<style scoped>
.large-width-only {
  display: flex;
  margin: auto 0 auto 5px;
}

h4#nome-usuario {
  color: #3d4441;
}

p#boas-vindas {
  color: #3d4441;
}
</style>
